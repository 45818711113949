exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-work-index-js": () => import("./../../../src/pages/work/index.js" /* webpackChunkName: "component---src-pages-work-index-js" */),
  "component---src-pages-work-mdx-slug-js": () => import("./../../../src/pages/work/{mdx.slug}.js" /* webpackChunkName: "component---src-pages-work-mdx-slug-js" */),
  "component---src-pages-writing-index-js": () => import("./../../../src/pages/writing/index.js" /* webpackChunkName: "component---src-pages-writing-index-js" */),
  "component---src-pages-writing-mdx-slug-js": () => import("./../../../src/pages/writing/{mdx.slug}.js" /* webpackChunkName: "component---src-pages-writing-mdx-slug-js" */)
}

